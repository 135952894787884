import { styled, Grid, Typography, WithTheme } from '@material-ui/core'

interface Props extends WithTheme {
  style?: {
    active?: number
    color?: string
  }
}

export const Wrapper = styled('div')(({ theme, style }: Props) => ({
  borderRadius: theme.shape.borderRadius,
  borderLeftWidth: style?.color ? theme.spacing(0.75) : 0,
  borderLeftColor: style?.color ? style.color : 'none',
  borderLeftStyle: 'solid',
  marginBottom: theme.spacing(1.5),
  padding: theme.spacing(1, 1.5),
  cursor: 'pointer',
  boxSizing: 'border-box',
  backgroundColor: style?.active
    ? theme.palette.primary.main
    : theme.palette.background.paper,
  '&:hover': {
    backgroundColor: style?.active
      ? theme.palette.primary.main
      : theme.palette.primary.dark,
    '& *': {
      color: theme.palette.common.white,
    },
  },
}))

export const Link = styled('a')(({ theme }) => ({
  textDecoration: 'none',
}))

export const IconWrapper = styled(Grid)({
  flexGrow: 0,
})

export const Title = styled(Typography)(({ theme, style }: Props) => ({
  fontSize: 14,
  fontWeight: 100,
  display: 'flex',
  alignItems: 'center',
  color: style?.active
    ? theme.palette.common.white
    : style?.color
    ? style.color
    : theme.palette.primary.main,
}))
