import jwtDecode from 'jwt-decode'

const decode = (token: string): any => {
  return jwtDecode(token)
}

const getUserIdFromToken = (token: string): string => {
  return decode(token).oid
}

export default { decode, getUserIdFromToken }
