import { Grid, styled, WithTheme } from '@material-ui/core'

interface FadeProps extends WithTheme {
  in?: boolean
}

export const Wrapper = styled(Grid)({
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 42, 95,0.25)',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 999999,
})
