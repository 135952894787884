import React, { FunctionComponent } from 'react'
import { ListItem } from './SelectItem.style'
import { ListItemProps } from '@material-ui/core'

export interface SelectItemProps extends ListItemProps {
  onClick: () => void
  active?: boolean | number
}

const SelectItem: FunctionComponent<SelectItemProps> = ({
  children,
  onClick,
  active = false,
  ...props
}) => {
  return (
    <ListItem button active={Number(active)} onClick={onClick} {...props}>
      {children}
    </ListItem>
  )
}

export default SelectItem
