import momentFn from 'moment'
import config from 'config'
import * as R from 'ramda'
import { CalendarDate } from 'types'

import HOLIDAYS from 'constants/holidays'

export const moment = (date?: CalendarDate, format?: string) => {
  return momentFn(date, format) as momentFn.Moment
}

export const formatDate = (
  date: CalendarDate,
  format = config.DEFAULT_DATE_FORMAT
) => moment(date).format(format)

export const formatDateWithOrdinalDay = (date: CalendarDate) => {
  const d = moment(date)
  const dayName = d.format('dddd')
  const dayNumber = getOrdinal(d.date())
  const monthName = d.format('MMMM')
  return `${dayName}, ${dayNumber} ${monthName}`
}

export const isSameDay = (date1: CalendarDate, date2: CalendarDate) =>
  moment(formatDate(date1)).isSame(formatDate(date2))

export const isDayInArray = (date1: CalendarDate, arr: CalendarDate[]) =>
  R.includes(formatDate(date1), R.map(formatDate, arr))

export const areDatesFromSameMonth = (
  date1: CalendarDate,
  date2: CalendarDate
) => {
  const m2 = moment(date2)
  const startOfMonth = moment(date1)
    .startOf('month')
    .subtract(1, 'day')
    .toDate()
  const endOfMonth = moment(date1).endOf('month').toDate()
  const isBetween = m2.isBetween(startOfMonth, endOfMonth)
  return isBetween
}

export const isWeekend = (date: CalendarDate) => {
  const day = moment(date).day()
  return R.includes(day, [6, 0])
}

export const isHoliday = (date: CalendarDate) => {
  const dateString = moment(date, 'YYYY/MM/DD').format('DD.MM.YYYY')
  return HOLIDAYS.includes(dateString)
}

export const isDayOff = (date: CalendarDate) =>
  isWeekend(date) || isHoliday(date)

export const getDaysInMonth = (date?: CalendarDate) => {
  const days = []
  const dateStart = moment(date).startOf('month')
  const dateEnd = moment(date).endOf('month').subtract(1, 'days')
  while (dateEnd.diff(dateStart, 'days') >= 0) {
    days.push(dateStart.format())
    dateStart.add(1, 'days')
  }
  return days
}

export const getWorkDaysInMonth = (date?: CalendarDate) => {
  const days = []
  const dateStart = moment(date).startOf('month')
  const dateEnd = moment(date).endOf('month').subtract(1, 'days')
  while (dateEnd.diff(dateStart, 'days') >= 0) {
    if (!isWeekend(dateStart)) days.push(dateStart.toISOString())
    dateStart.add(1, 'days')
  }
  return days
}

export const getFirstDayOfMonth = (date: CalendarDate) =>
  formatDate(moment(date).startOf('month'))

export const getLastDayOfMonth = (date: CalendarDate) =>
  formatDate(moment(date).endOf('month'))

export const isDateFromPastMonth = (date: CalendarDate) => {
  const today = moment().startOf('month')
  const day = moment(date)
  return day.isBefore(today)
}

export const getMonthsAround = (date?: CalendarDate) => {
  const months = [moment(date)]
  Array(6)
    .fill('')
    .forEach((_, index) => {
      const prev = moment(date).subtract(index + 1, 'months')
      const next = moment(date).add(index + 1, 'months')
      months.unshift(prev)
      months.push(next)
    })
  return months
}

export const getOrdinal = (number: number) => {
  switch (number) {
    case 1:
    case 21:
      return number + 'st'
    case 2:
    case 22:
      return number + 'nd'
    case 3:
    case 23:
      return number + 'rd'
    default:
      return number + 'th'
  }
}
