import { styled, Grid } from '@material-ui/core'
import { Search } from '@material-ui/icons'

export const SearchIcon = styled(Search)(({ theme }) => ({
  fill: theme.palette.primary.main,
  marginRight: theme.spacing(0.5),
  width: 24,
  height: 24,
}))

export const InputWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.info.main}`,
  boxSizing: 'border-box',
  padding: theme.spacing(1),
}))
