import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Icon = (props: SvgIconProps) => (
  <SvgIcon
    width="150px"
    height="150px"
    viewBox="0 0 150 150"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Loader" transform="translate(-117.000000, -93.000000)">
        <g id="MMN-Loader" transform="translate(117.000000, 93.000000)">
          <path
            d="M74.9003785,0 C70.0938048,0 65.3948008,0.458964143 60.8404183,1.32370518 C41.7402191,4.95179283 25.2109363,15.8300797 14.2208964,30.990239 C2.51432271,53.7543825 8.1199004,82.3452191 28.956753,98.8213147 C34.9997809,103.599801 41.742012,106.888446 48.7406175,108.755976 C61.6884263,112.211952 75.5147211,110.769323 87.4615339,104.760956 C83.5985857,106.387052 79.3543625,107.287649 74.9003785,107.287649 C57.0127291,107.287649 42.5123307,92.7878486 42.5123307,74.9001992 C42.5123307,73.4982072 42.6115339,72.1207171 42.784243,70.7659363 C43.3065538,66.6711155 44.5854382,62.8141434 46.4888247,59.3450199 C55.0651195,40.3733068 74.1551594,27.1715139 96.3282669,27.1715139 C101.208944,27.1715139 105.939024,27.8145418 110.44261,29.0151394 C129.893606,34.1994024 145.08006,49.838247 149.608147,69.5492032 C146.863924,30.6806773 114.466912,0 74.9003785,0"
            id="Fill-1"
            fill="#2995C4"
          ></path>
          <path
            d="M149.607968,69.5491434 C145.07988,49.8381873 129.894024,34.1993426 110.44243,29.0150797 C105.939442,27.8144821 101.209363,27.1714542 96.3286853,27.1714542 C74.1549801,27.1714542 55.0649402,40.373247 46.4886454,59.3449602 C51.9926295,49.3140837 62.650996,42.5126892 74.9001992,42.5126892 C92.787251,42.5126892 107.288247,57.0130876 107.288247,74.9001394 C107.288247,84.5264343 103.084064,93.1666733 96.4177291,99.0985458 C93.7810757,101.444761 90.7619522,103.370259 87.4613546,104.760896 C75.5151394,110.769861 61.688247,112.211892 48.7404382,108.755916 C41.7418327,106.888386 35.0001992,103.600339 28.9565737,98.821255 C8.11972112,82.3451594 2.51414343,53.7543227 14.2207171,30.9907769 C5.27689243,43.3278287 0,58.4969522 0,74.9001394 C0,116.266673 33.5342629,149.800339 74.9001992,149.800339 C97.9703187,149.800339 118.599801,139.367271 132.339442,122.965876 C143.236255,109.957709 149.800398,93.1965538 149.800398,74.9001394 C149.800398,73.1001394 149.732869,71.3168725 149.607968,69.5491434"
            id="Fill-3"
            fill="#1F3058"
          ></path>
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default Icon
