import { createSlice } from '@reduxjs/toolkit'
import {
  ApiResource,
  defaultApiResource,
  handleApiResourceBasePending,
  handleApiResourceFulfilled,
  handleApiResourceRejected,
} from '../helpers'
import fetchDashboardAction from './actions/fetch-dashboard.action'
import { NAMESPACE } from './constants'
import { DashboardDto } from './dtos/dashboard.dto'

interface DashboardState {
  dashboard: {
    data: ApiResource<DashboardDto>
  }
}

const initialState: DashboardState = {
  dashboard: {
    data: defaultApiResource(),
  },
}

const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(fetchDashboardAction.pending, state =>
        handleApiResourceBasePending(state.dashboard.data)
      )
      .addCase(fetchDashboardAction.fulfilled, (state, action) =>
        handleApiResourceFulfilled(state.dashboard.data, action.payload.data)
      )
      .addCase(fetchDashboardAction.rejected, (state, action) =>
        handleApiResourceRejected(state.dashboard.data, action.error)
      ),
})

export const actions = { ...slice.actions, ...slice.caseReducers }

export default { reducer: slice.reducer }
