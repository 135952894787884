import React, { useEffect, useRef } from 'react'
import { Wrapper } from './OverflowFade.style'

const updateElement = (current: HTMLElement, parent: HTMLElement) => {
  const newTop = parent.clientHeight + parent.scrollTop - current.clientHeight
  current.style.top = `${newTop}px`
  const isAtBottom =
    parent.scrollTop + parent.clientHeight >= parent.scrollHeight
  if (isAtBottom) current.style.opacity = '0'
  else current.style.opacity = '1'
}

const OverflowFade: React.FC = () => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const currentElement = wrapperRef.current!
    const parentElement = currentElement.parentElement!

    const update = updateElement.bind(window, currentElement, parentElement)

    const updateDelayed = () => setTimeout(() => update(), 50)

    parentElement.addEventListener('scroll', update)
    parentElement.addEventListener('mousedown', updateDelayed)
    parentElement.addEventListener('mouseup', updateDelayed)
    window.addEventListener('resize', update)

    update()

    return () => {
      parentElement.removeEventListener('scroll', update)
      parentElement.removeEventListener('mousedown', updateDelayed)
      parentElement.removeEventListener('mouseup', updateDelayed)
      window.removeEventListener('resize', update)
    }
  }, [])

  return <Wrapper ref={wrapperRef} />
}

export default OverflowFade
