import React, { FunctionComponent } from 'react'
import {
  Button as BaseButton,
  ButtonProps as BaseButtonProps,
} from '@material-ui/core'
import { Spinner } from 'components'

interface ButtonProps extends BaseButtonProps {
  loading?: boolean
}

const Button: FunctionComponent<ButtonProps> = ({
  children,
  loading = false,
  disabled,
  ...props
}) => (
  <BaseButton {...props} disabled={disabled || loading}>
    {loading && <Spinner />}
    {children}
  </BaseButton>
)

export default Button
