import React, { useState } from 'react'
import { getIconStyles } from '../LeftMenu.style'
import LeftMenuItem from '../LeftMenuItem'
import { MenuItem } from '../MenuItems'
import {
  Wrapper,
  Heading,
  Title,
  DropdownIcon,
  List,
} from './LeftMenuDropdown.style'

interface LeftMenuDropdownProps {
  isActiveHandler: (paths: string[]) => boolean
  item: MenuItem
  onItemClick?: (path: string) => void
  color?: string
  borderColor?: string
}

const LeftMenuDropdown: React.FunctionComponent<LeftMenuDropdownProps> = ({
  isActiveHandler,
  item,
  onItemClick,
  color,
  borderColor,
}) => {
  const isDropdownActive = isActiveHandler(
    item.dropdownItems ? item.dropdownItems.map(item => item.path) : []
  )

  const [expanded, setExpanded] = useState(isDropdownActive)

  const isExpanded = expanded || isDropdownActive

  return (
    <Wrapper>
      <Heading
        onClick={() => setExpanded(!expanded)}
        styled={{
          active: isDropdownActive,
          color,
          borderColor,
        }}
      >
        <item.icon style={getIconStyles()} />
        <Title>{item.title}</Title>
        <DropdownIcon styled={{ expanded: isExpanded }} />
      </Heading>
      <List styled={{ display: isExpanded }}>
        {item &&
          item.dropdownItems &&
          item.dropdownItems.map((item, i) => (
            <LeftMenuItem
              isActiveHandler={isActiveHandler}
              key={`dropdown-${item.title}-${i}`}
              item={item}
              onClick={() => onItemClick && onItemClick(item.path)}
              color={item.barColor}
            />
          ))}
      </List>
    </Wrapper>
  )
}

export default LeftMenuDropdown
