import AssessmentIcon from '@material-ui/icons/Assessment'
import AssignmentIcon from '@material-ui/icons/Assignment'
import DolarIcon from '@material-ui/icons/AttachMoney'
import BusinessCenter from '@material-ui/icons/BusinessCenter'
import EmojiEvents from '@material-ui/icons/EmojiEvents'
import HealingIcon from '@material-ui/icons/Healing'
import CalendarIcon from 'icons/Calendar'
import DashboardIcon from 'icons/Dashboard'
import dashboard from 'modules/dashboard'
import digitalCalendar from 'modules/digitalCalendar'
import profile from 'modules/profile'
import { getI18n } from 'react-i18next'
import { FaGlobeAmericas as GlobeIcon } from 'react-icons/fa'
import { ImStatsDots as StatsIcon } from 'react-icons/im'
import { palette } from '../../../config/theme'
import externalLinks from '../../../constants/external-links'

export enum MenuItemType {
  APP,
  EXTERNAL,
  DROPDOWN,
  PDF,
}

export enum MenuItemKey {
  Dashboard = 'dashboard',
  DailyHealthcheck = 'dailyHealthcheck',
  Calendars = 'calendars',
  MyFinance = 'myFinance',
  Wellness = 'wellness',
  MyReports = 'myReports',
  QuickCap = 'quickCap',
  FirstChoiceNet = 'firstChoiceNet',
  BusinessCentral = 'businessCentral',
  MMN = 'MMN',
  Cozeva = 'cozeva',
}

export interface MenuItem {
  name?: string
  icon?: any
  path?: string
  title: string
  type: MenuItemType
  dropdownItems?: DropdownMenuItem[]
  color?: string
}

export interface DropdownMenuItem {
  icon?: any
  path: string
  title: string
  type: MenuItemType
  barColor?: string
}

export const menuItems = {
  [MenuItemKey.Dashboard]: {
    icon: DashboardIcon,
    path: dashboard.path,
    title: getI18n().t('HOME'),
    type: MenuItemType.APP,
  },
  [MenuItemKey.DailyHealthcheck]: {
    icon: HealingIcon,
    path: externalLinks.dailyHealthcheckOfficeForms,
    title: getI18n().t('DAILY_HEALTHCHECK'),
    type: MenuItemType.EXTERNAL,
  },
  [MenuItemKey.Calendars]: {
    icon: CalendarIcon,
    type: MenuItemType.DROPDOWN,
    title: 'Schedule',
    dropdownItems: [
      {
        path: profile.path,
        title: profile.title,
        type: MenuItemType.APP,
        barColor: palette.primary.main,
      },
      {
        path: digitalCalendar.path,
        title: digitalCalendar.title,
        type: MenuItemType.APP,
        barColor: palette.primary.main,
      },
    ],
  },
  [MenuItemKey.MyFinance]: {
    icon: DolarIcon,
    type: MenuItemType.DROPDOWN,
    title: getI18n().t('MY_FINANCE'),
    dropdownItems: [
      {
        path: externalLinks.payrollEselfserve,
        title: getI18n().t('MY_PAYROLL'),
        type: MenuItemType.EXTERNAL,
        barColor: palette.primary.main,
      },
      {
        path: externalLinks.benefits,
        title: getI18n().t('MY_BENEFITS'),
        type: MenuItemType.EXTERNAL,
        barColor: palette.primary.main,
      },
      {
        path: externalLinks.fidelity,
        title: getI18n().t('401K'),
        type: MenuItemType.EXTERNAL,
        barColor: palette.primary.main,
      },
    ],
  },
  [MenuItemKey.Wellness]: {}, // Populated in the component
  [MenuItemKey.MyReports]: {
    icon: AssessmentIcon,
    path: externalLinks.powerbi,
    title: getI18n().t('MY_REPORTS'),
    type: MenuItemType.EXTERNAL,
  },
  [MenuItemKey.QuickCap]: {
    icon: AssignmentIcon,
    path: externalLinks.quickcap,
    title: getI18n().t('QUICKCAP'),
    type: MenuItemType.EXTERNAL,
  },
  // [MenuItemKey.FirstChoiceNet]: {
  //   icon: EmojiEvents,
  //   path: externalLinks.firstChoiceNet,
  //   title: getI18n().t('FIRST_CHOICE_NET'),
  //   type: MenuItemType.EXTERNAL,
  // },
  [MenuItemKey.MMN]: {
    icon: GlobeIcon,
    path: externalLinks.mmn,
    title: 'MeritageNet Portal',
    type: MenuItemType.EXTERNAL,
  },
  [MenuItemKey.Cozeva]: {
    icon: StatsIcon,
    path: externalLinks.cozeva,
    title: 'Cozeva Analytics',
    type: MenuItemType.EXTERNAL,
  },
  [MenuItemKey.BusinessCentral]: {
    icon: BusinessCenter,
    type: MenuItemType.DROPDOWN,
    title: getI18n().t('BUSINESS_CENTRAL'),
    dropdownItems: [
      {
        path: externalLinks.businessCentralMMN,
        title: 'MMN',
        type: MenuItemType.EXTERNAL,
        barColor: palette.primary.main,
      },
      {
        path: externalLinks.businessCentralPMG,
        title: 'PMG',
        type: MenuItemType.EXTERNAL,
        barColor: palette.primary.main,
      },
      {
        path: externalLinks.businessCentralMHP,
        title: 'MHP',
        type: MenuItemType.EXTERNAL,
        barColor: palette.primary.main,
      },
    ],
  },
} as Record<MenuItemKey, MenuItem>
