import { asyncThunkFactory } from 'store/helpers'
import { WorkStatus } from 'types'
import { get } from 'utils'
import { NAMESPACE } from '../constants'

export interface CurrentUserDto {
  id: string
  name: string
  firstName: string
  lastName: string
  email: string
  imageUrl: string
  role: string
  departments: { id: number; name: number }[]
  schedules: { id: number; type: WorkStatus; date: string }[]
}

export default asyncThunkFactory<CurrentUserDto, unknown>(
  NAMESPACE,
  'fetchCurrentUser',
  async params => {
    const path = ['users', 'me'].join('/')
    const response = await get(path)
    return response.data
  }
)
