import { createSlice } from '@reduxjs/toolkit'
import * as auth from 'auth'
import { NAMESPACE } from 'store/articles/constants'
import {
  ApiResource,
  defaultApiResource,
  defaultPaginatedApiResource,
  handleApiResourceBasePending,
  handleApiResourceFulfilled,
  handleApiResourceRejected,
  handlePaginatedApiResourceFulfilled,
  PaginatedApiResource,
} from 'store/helpers'
import fetchCurrentUserAction, {
  CurrentUserDto,
} from './actions/fetch-current-user.action'
import fetchUsersBasicAction, {
  UserBasicDto,
} from './actions/fetch-users-basic.action'
import fetchUsersAction, { UserDto } from './actions/fetch-users.action'
import loginAction from './actions/login.action'
import { logout } from './actions/logout.action'
import resetUsersAction from './actions/reset-users.action'

export interface UserState {
  login: {
    isLoading: boolean
  }
  currentUser: ApiResource<CurrentUserDto>
  usersBasic: PaginatedApiResource<UserBasicDto>
  allUsers: PaginatedApiResource<UserDto>
}

const initialState: UserState = {
  login: {
    isLoading: false,
  },
  currentUser: defaultApiResource<CurrentUserDto>(),
  usersBasic: defaultPaginatedApiResource<UserBasicDto>(),
  allUsers: defaultPaginatedApiResource<UserDto>(),
}

const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(logout, () => {
        auth.logout()
      })
      .addCase(loginAction.pending, state => {
        state.login.isLoading = true
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        auth.login(action.payload)
        state.login.isLoading = false
      })
      .addCase(loginAction.rejected, state => {
        state.login.isLoading = false
      })
      .addCase(fetchCurrentUserAction.pending, state =>
        handleApiResourceBasePending(state.currentUser)
      )
      .addCase(fetchCurrentUserAction.fulfilled, (state, action) =>
        handleApiResourceFulfilled(state.currentUser, action.payload)
      )
      .addCase(fetchCurrentUserAction.rejected, (state, action) =>
        handleApiResourceRejected(state.currentUser, action.error)
      )
      .addCase(fetchUsersBasicAction.pending, state =>
        handleApiResourceBasePending(state.usersBasic)
      )
      .addCase(fetchUsersBasicAction.fulfilled, (state, action) =>
        handlePaginatedApiResourceFulfilled(state.usersBasic, action.payload)
      )
      .addCase(fetchUsersBasicAction.rejected, (state, action) =>
        handleApiResourceRejected(state.usersBasic, action.error)
      )
      .addCase(fetchUsersAction.pending, state =>
        handleApiResourceBasePending(state.allUsers)
      )
      .addCase(fetchUsersAction.fulfilled, (state, action) =>
        handlePaginatedApiResourceFulfilled(state.allUsers, action.payload)
      )
      .addCase(fetchUsersAction.rejected, (state, action) =>
        handleApiResourceRejected(state.allUsers, action.error)
      )
      .addCase(resetUsersAction, state => {
        state.allUsers = defaultPaginatedApiResource<UserDto>()
      }),
})

export default slice
