import { Route } from 'types'
import { Loadable } from 'components'

export const paths = {
  department: '/departments/:departmentName',
  article: '/articles/:articleId',
}

export const routes: Route[] = [
  {
    path: paths.department,
    exact: true,
    component: Loadable({
      component: () => import('./components/Views/Department/Department'),
    }),
  },
  {
    path: paths.article,
    exact: true,
    component: Loadable({
      component: () => import('./components/Views/Article/Article'),
    }),
  },
]

export default routes
