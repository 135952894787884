import { asyncThunkFactory } from 'store/helpers'
import { ArticleType } from 'types'
import { get } from 'utils'
import { NAMESPACE } from '../constants'

export interface ArticleDto {
  id: string
  title: string
  description: string
  thumbnail: string
  type: ArticleType
  url: string
  pdf: string
  content: string
}

export interface FetchArticleParams {
  articleId: string
}

export default asyncThunkFactory<ArticleDto, FetchArticleParams>(
  NAMESPACE,
  'fetchArticles',
  async params => {
    const response = await get(`articles/${params.articleId}`)
    return response.data
  }
)
