import React, { useState } from 'react'
import { ClickAwayListener } from '@material-ui/core'
import DropdownIcon from './dropdown.svg'
import SelectItem from './SelectItem'
import {
  Icon,
  Wrapper,
  Collapse,
  List,
  Title,
  InputWrapper,
  DisplayValueWrapper,
  ValueDisplay,
} from './Select.style'

export type OptionValue = string | number

export type Option<T extends OptionValue> = {
  title: string
  value: T
}

interface SelectProps<T extends OptionValue> {
  onChange: (value: T) => any
  options?: Option<T>[]
  placeholder?: string
  title?: string
  disabled?: boolean
  name?: string
  value?: T
}

function Select<T extends OptionValue>({
  options = [],
  name = 'example-input',
  onChange,
  value,
  disabled = false,
  title,
  placeholder,
}: SelectProps<T>) {
  const [isOpen, setIsOpen] = useState(false)
  const openDropdown = () => setIsOpen(true)
  const closeDropdown = () => setIsOpen(false)
  const onSelect = (value: T) => {
    onChange(value)
    closeDropdown()
  }

  const defaultTitle =
    placeholder === undefined ? options[0]?.value : placeholder

  const inputValue =
    value === undefined
      ? defaultTitle
      : options.find(item => item.value === value)?.title

  return (
    <ClickAwayListener onClickAway={closeDropdown}>
      <Wrapper>
        {title && <Title>{title}</Title>}
        <InputWrapper value={value}>
          <DisplayValueWrapper onClick={isOpen ? closeDropdown : openDropdown}>
            <ValueDisplay>{inputValue}</ValueDisplay>
            <Icon src={DropdownIcon} open={isOpen} />
          </DisplayValueWrapper>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List disablePadding>
              {options.map(item => (
                <SelectItem
                  key={`select-${name}-${item.title}-${item.value}`}
                  onClick={() => onSelect(item.value)}
                  active={item.value === value}
                >
                  {item.title}
                </SelectItem>
              ))}
            </List>
          </Collapse>
        </InputWrapper>
      </Wrapper>
    </ClickAwayListener>
  )
}

export default Select
