import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import enTranslation from './en.json'

const resources = {
  en: { global: enTranslation },
}

const settings = {
  resources,
  defaultNS: 'global',
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
}

export default () => {
  i18n.use(initReactI18next).init(settings)
  return i18n
}
