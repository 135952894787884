import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Icon = (props: SvgIconProps) => (
  <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.3 0H1.8C0.8073 0 0 0.8082 0 1.8V6.3C0 7.2927 0.8073 8.1 1.8 8.1H6.3C7.2927 8.1 8.1 7.2927 8.1 6.3V1.8C8.1 0.8082 7.2927 0 6.3 0Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2 0H11.7C10.7073 0 9.90002 0.8082 9.90002 1.8V6.3C9.90002 7.2927 10.7073 8.1 11.7 8.1H16.2C17.1927 8.1 18 7.2927 18 6.3V1.8C18 0.8082 17.1927 0 16.2 0Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.3 9.9H1.8C0.8073 9.9 0 10.7082 0 11.7V16.2C0 17.1927 0.8073 18 1.8 18H6.3C7.2927 18 8.1 17.1927 8.1 16.2V11.7C8.1 10.7082 7.2927 9.9 6.3 9.9Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2 9.9H11.7C10.7073 9.9 9.90002 10.7082 9.90002 11.7V16.2C9.90002 17.1927 10.7073 18 11.7 18H16.2C17.1927 18 18 17.1927 18 16.2V11.7C18 10.7082 17.1927 9.9 16.2 9.9Z"
    />
  </SvgIcon>
)

export default Icon
