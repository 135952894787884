export const schedules = {
  BASE: 'schedules',
  UPDATE_SCHEDULES: (id: number) => `${schedules.BASE}/${id}`,
  get USERS_STATS_CSV() {
    return `${this.BASE}/stats/users/csv`
  },
  get STATS() {
    return `${this.BASE}/stats`
  },
  get USER_STATS() {
    return `${this.STATS}/user`
  },
  get USERS_STATS() {
    return `${this.STATS}/users`
  },

  get DAY_STATS() {
    return `${this.STATS}/day`
  },
}

export const users = {
  BASE: 'users',
  get CURRENT_USER() {
    return `${this.BASE}/me`
  },
}

export const departments = {
  BASE: 'departments',
}
