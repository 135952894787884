import { configureStore, combineReducers } from '@reduxjs/toolkit'

import uiSlice from './ui/slice'
import departmentsSlice from './departments/slice'
import schedulesSlice from './schedules/slice'
import userSlice from './user/slice'
import articlesSlice from './articles/slice'
import uploadsSlice from './uploads/slice'
import dashboardSlice from './dashboard/slice'

const reducer = combineReducers({
  ui: uiSlice.reducer,
  departments: departmentsSlice.reducer,
  schedules: schedulesSlice.reducer,
  user: userSlice.reducer,
  articles: articlesSlice.reducer,
  uploads: uploadsSlice.reducer,
  dashboard: dashboardSlice.reducer,
})

export const store = configureStore({
  reducer,
})

export type Store = typeof store

export type RootState = ReturnType<typeof reducer>

export default store
