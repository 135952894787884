import { get } from 'utils'
import { asyncThunkFactory } from 'store/helpers'
import { NAMESPACE } from '../constants'
import { DashboardDto } from '../dtos/dashboard.dto'

export interface GetDashboardDto {
  data: DashboardDto
}

export interface FetchDashboardParams {}

export default asyncThunkFactory<GetDashboardDto, FetchDashboardParams>(
  NAMESPACE,
  'fetchDashboard',
  async () => {
    const path = ['dashboard'].join('/')
    const response = await get(path)
    return response.data
  }
)
