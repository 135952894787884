import { WithTheme, ListItem as BaseListItem, styled } from '@material-ui/core'
import { SelectItemProps } from './SelectItem'

interface ListItemProps extends SelectItemProps, WithTheme {}

export const ListItem = styled(BaseListItem)(
  ({ theme, active }: ListItemProps) => ({
    textOverflow: 'ellipsis',
    fontWeight: active ? 'bold' : 'normal',
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    fontSize: 16,
    lineHeight: '20px',
    boxSizing: 'border-box',
    padding: `${theme.spacing(0.75, 2.5)} !important`,
    transition: '.3s',
    '&:hover': {
      color: theme.palette.secondary.light,
    },
  })
) as (props: SelectItemProps) => JSX.Element
