import Filters from './filters.svg'
import Off from './off.svg'
import Office from './office.svg'
import Remote from './remote.svg'
import ChevronLeft from './chevronLeft.svg'
import ChevronRight from './chevronRight.svg'
import Multiselect from './multiselect.svg'
import Holiday from './holiday.svg'

export default {
  Filters,
  Off,
  Office,
  Remote,
  ChevronLeft,
  ChevronRight,
  Multiselect,
  Holiday,
}
