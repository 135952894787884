import { styled, Grid, ListItem as ListItemBase } from '@material-ui/core'
import { theme } from 'config'

const ICON_WIDTH = 18

export const Wrapper = styled(Grid)(({ theme }) => ({
  height: '100%',
  boxSizing: 'border-box',
  padding: theme.spacing(2),
  flex: '0 0 270px',
  flexWrap: 'nowrap',
  backgroundColor: theme.palette.common.white,
  borderRight: `1px solid ${theme.palette.info.main}`,
  '& > ul': {
    width: '100%',
  },
}))

export const ListItem = styled(ListItemBase)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  '&:not(:last-child)': {
    marginBottom: theme.spacing(1.5),
  },
}))

export const getIconStyles = () => ({
  width: ICON_WIDTH,
  marginRight: theme.spacing(1.25),
})
