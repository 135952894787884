import {
  AppBar as BaseAppBar,
  Toolbar as BaseToolbar,
  Button as BaseButton,
  Avatar as AvatarBase,
  Grid,
  Typography,
  styled,
} from '@material-ui/core'

export const TOP_BAR_HEIGHT = 86
export const LEFT_MENU_WIDTH = 270
export const LOGO_WIDTH = 186

export const AppBar = styled(BaseAppBar)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: theme.palette.common.white,
}))

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: 32,
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

export const Button = styled(BaseButton)(({ theme }) => ({
  fontSize: 16,
  marginLeft: theme.spacing(),
  fontWeight: 'bold',
  color: theme.palette.common.white,
}))

export const LogoutButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.light,
  marginRight: theme.spacing(),
}))

export const Toolbar = styled(BaseToolbar)(({ theme }) => ({
  height: TOP_BAR_HEIGHT,
  padding: 0,
  boxSizing: 'border-box',
  borderBottom: `1px solid ${theme.palette.info.main}`,
}))

export const LogoContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  minWidth: LEFT_MENU_WIDTH,
  maxWidth: LEFT_MENU_WIDTH,
})

export const Logo = styled('img')({
  width: LOGO_WIDTH,
})

export const SearchWrapper = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
})

export const Content = styled('div')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(1, 4, 1, 1),
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-end',
  },
}))

export const Avatar = styled(AvatarBase)(({ theme }) => ({
  width: 50,
  height: 50,
  boxSizing: 'border-box',
  border: `2px solid ${theme.palette.info.dark}`,
}))
