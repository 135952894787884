import React, { FunctionComponent } from 'react'
import { GridProps } from '@material-ui/core'
import { Wrapper } from './Header.style'
import baseHeaderImage from 'images/base-header.jpg'

export interface HeaderProps extends GridProps {
  url?: string
  noBackground?: boolean
}

const Header: FunctionComponent<HeaderProps> = ({
  url = baseHeaderImage,
  noBackground = false,
  children,
  ...props
}) => (
  <Wrapper container styled={{ noBackground, url }} {...props}>
    {children}
  </Wrapper>
)

export default Header
