import {
  Grid,
  styled,
  IconButton as BaseIconButton,
  Typography,
} from '@material-ui/core'

const PAGINATION_HEIGHT = 32

export const Wrapper = styled(Grid)(({ theme }) => ({
  width: '100%',
  height: PAGINATION_HEIGHT,
  padding: theme.spacing(0, 2),
  borderTop: `1px solid ${theme.palette.info.light}`,
}))

export const IconButton = styled(BaseIconButton)({
  width: PAGINATION_HEIGHT,
  height: PAGINATION_HEIGHT,
  padding: 0,
})

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  lineHeight: '16px',
  color: theme.palette.text.secondary,
  fontFamily: 'Roboto',
  marginRight: theme.spacing(2.5),
}))
