import { paths as loginPaths } from 'modules/login/routing'
import { paths as dashboardPaths } from 'modules/dashboard/routing'
import config from 'config'
import googleAnalytics from 'config/google-analytics'
import jwt from 'config/jwt'

export const logout = () => {
  localStorage.removeItem(config.APP_TOKEN_KEY)
  localStorage.removeItem(config.TOKEN_EXPIRE_AT)
  localStorage.removeItem(config.REFRESH_TOKEN_KEY)
  window.location.replace(loginPaths.root)
  googleAnalytics.clearUserId()
}

export const login = (token: string) => {
  localStorage.setItem(config.APP_TOKEN_KEY, token)
  window.location.replace(dashboardPaths.root)
  googleAnalytics.setUserId(jwt.getUserIdFromToken(token))
}

export const handleUnauthorizedApiResponseError = (err: any) => {
  if (
    err.response &&
    (err.response.status === 401 || err.response.status === 403)
  ) {
    logout()
  }
}
