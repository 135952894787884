import React, { Suspense } from 'react'
import DefaultSpinner from './Spinner'

interface Options {
  component: () => Promise<{ default: React.ComponentType<any> }>
  spinner?: () => JSX.Element
}

const Loadable = ({
  component,
  spinner: Spinner = DefaultSpinner,
}: Options) => {
  const Component = React.lazy(component)
  return (props: any) => (
    <Suspense fallback={<Spinner />}>
      <Component {...props} />
    </Suspense>
  )
}

export default Loadable
