import { createSlice } from '@reduxjs/toolkit'
import { DepartmentDto } from 'hooks/useFetchDepartments'
import {
  defaultListedApiResource,
  handleApiResourceBasePending,
  handleListedApiResourceFulfilled,
  handleListedApiResourceRejected,
  ListedApiResource,
} from 'store/helpers'
import fetchDepartmentsAction from './actions/fetch-departments.action'
import { REDUCER_NAME } from './config'

export interface DepartmentsState {
  departments: ListedApiResource<DepartmentDto>
}

const initialState: DepartmentsState = {
  departments: defaultListedApiResource<DepartmentDto>(),
}

const slice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(fetchDepartmentsAction.pending, state =>
        handleApiResourceBasePending(state.departments)
      )
      .addCase(fetchDepartmentsAction.fulfilled, (state, action) =>
        handleListedApiResourceFulfilled(state.departments, action.payload)
      )
      .addCase(fetchDepartmentsAction.rejected, (state, action) =>
        handleListedApiResourceRejected(state.departments, action.payload)
      ),
})

export const actions = slice.actions

export default slice
