import { asyncThunkFactory } from 'store/helpers'
import { WorkStatus } from 'types'
import { post } from 'utils'
import { NAMESPACE } from '../constants'

export interface CreateSchedulesDto {
  date: string
  type: WorkStatus
}

export interface CreateSchedulesParams {
  userId: string
  body: { schedules: CreateSchedulesDto[] }
}

export default asyncThunkFactory<CreateSchedulesDto, CreateSchedulesParams>(
  NAMESPACE,
  'createSchedules',
  async params => {
    const path = ['users', params.userId, 'schedules'].join('/')
    const response = await post(path, { data: params.body })
    return response.data
  }
)
