import { colors } from 'config'
import icons from 'icons'
import { WorkStatus } from 'types'

export const OFFICE = 'office'

export const OFFICE_FRESNO = 'fresno'
export const OFFICE_PETALUMA = 'petaluma'

export const REMOTE = 'remote'
export const OFF = 'off'

export const workStatuses: WorkStatus[] = [
  OFFICE_FRESNO,
  OFFICE_PETALUMA,
  REMOTE,
  OFF,
]

export const statusConfig = {
  off: {
    color: colors.off,
    icon: icons.Off,
  },
  remote: {
    color: colors.remote,
    icon: icons.Remote,
  },
  office: {
    color: colors.office,
    icon: icons.Office,
  },
  petaluma: {
    color: colors.petaluma,
    icon: icons.Office,
  },
  fresno: {
    color: colors.fresno,
    icon: icons.Office,
  },
  holiday: {
    color: colors.holiday,
    icon: icons.Holiday,
  },
  unfilled: {
    color: colors.unfilled,
    icon: '',
  },
}
