import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'
import { Wrapper } from './Search.style'
import { useHistory, useParams } from 'react-router-dom'
import SearchInput from 'components/SearchInput'

const Search = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const { searchString } = useParams<{ searchString: string }>()

  const [searchValue, setSearchValue] = useState(
    searchString ? searchString : ''
  )

  const onSearchChange = (value?: string) => {
    if (!value) return setSearchValue('')
    setSearchValue(value)
  }

  const onSearchClick = () => {
    if (!searchValue) return
    history.push(`/search/${searchValue.toLowerCase()}`)
  }

  return (
    <Wrapper>
      <SearchInput
        value={searchValue}
        placeholder={`${t('SEARCH')}…`}
        onValueChange={value => onSearchChange(value)}
        onKeyDown={event => event.keyCode === 13 && onSearchClick()}
      />
      <Button
        style={{ marginLeft: 8 }}
        color="primary"
        variant="contained"
        onClick={onSearchClick}
      >
        {t('SEARCH')}
      </Button>
    </Wrapper>
  )
}

export default Search
