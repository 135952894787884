import React, { FunctionComponent, useEffect } from 'react'
import { fetchCurrentUser } from 'store/user/actions'
import { useDispatch } from 'react-redux'
import { Wrapper, Content, Container } from './View.style'
import TopBar from './TopBar'
import LeftMenu from './LeftMenu'
import OverflowFade from 'components/OverflowFade'
import fetchDepartmentsAction from 'store/departments/actions/fetch-departments.action'

interface ViewProps {
  title?: string
}

const View: FunctionComponent<ViewProps> = ({ children, title }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchCurrentUser())
    dispatch(fetchDepartmentsAction({}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Wrapper container direction="column">
      <TopBar title={title} />
      <Container>
        <LeftMenu />
        <Content>
          {children}
          <OverflowFade />
        </Content>
      </Container>
    </Wrapper>
  )
}

export default View
