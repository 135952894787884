import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/store'

export const getUserState = (state: RootState) => state.user

export const currentUser = createSelector(
  getUserState,
  state => state.currentUser
)
export const usersBasic = createSelector(
  getUserState,
  state => state.usersBasic
)
export const allUsers = createSelector(getUserState, state => state.allUsers)

export const login = createSelector(getUserState, state => state.login)
