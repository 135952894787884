import { departments } from 'config'
import { asyncThunkFactory } from 'store/helpers'
import { get } from 'utils/api'
import { NAMESPACE } from '../constants'

export interface DepartmentDto {
  id: string
  name: string
}

export interface FetchDepartmentsParams {}

export default asyncThunkFactory<DepartmentDto[], FetchDepartmentsParams>(
  NAMESPACE,
  'fetchDepartments',
  async () => {
    const response = await get(departments.BASE)
    return response.data
  }
)
