import React, { FunctionComponent } from 'react'
import { Route, Redirect } from 'react-router-dom'
import config from 'config'
import { paths } from 'modules/login/routing'
import { Route as RouteProps } from 'types'

const Component: FunctionComponent<RouteProps> = ({
  private: isPrivate,
  ...props
}) => {
  const isAuthorized = !!localStorage.getItem(config.APP_TOKEN_KEY)
  if (!isPrivate || isAuthorized) return <Route {...props} />
  return !isPrivate || isAuthorized ? (
    <Route {...props} />
  ) : (
    <Redirect to={paths.root} />
  )
}

export default Component
