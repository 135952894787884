export default {
  dailyHealthcheckOfficeForms:
    'https://forms.office.com/Pages/ResponsePage.aspx?id=yYJbFKZXw0KC4HtE-KKh3-iG7zyz-0dIlALMsEdf1c5UM1IzUlpWM0cyTk5XRkNCVVJSRDhFWVY5NC4u',
  payrollEselfserve:
    'https://www.eselfserve.com/index_ess.php#standard_mode_interface',
  benefits: 'https://mmn.ease.com',
  fidelity:
    'https://workplaceservices.fidelity.com/mybenefits/navstation/navigation',
  wellness:
    'https://datastudio.google.com/reporting/3e9a9a62-6792-4c99-afee-d3c615ef10aa/page/6B5WB',
  powerbi: 'https://app.powerbi.com/home',
  quickcap: 'https://quickcap.meritagemed.com/meritage/general/index.php',
  firstChoiceNet: 'https://firstchoicenet.meritagemed.com/login.aspx',
  mmn: 'https://meritagenet.com/login',
  cozeva: 'https://www.cozeva.com/user/login',
  businessCentralMMN:
    'https://businesscentral.dynamics.com/145b82c9-57a6-42c3-82e0-7b44f8a2a1df/Production/?dc=0&company=Meritage%20Medical%20Network',
  businessCentralPMG:
    'https://businesscentral.dynamics.com/145b82c9-57a6-42c3-82e0-7b44f8a2a1df/Production/?company=Prima%20Medical%20Group',
  businessCentralMHP:
    'https://businesscentral.dynamics.com/145b82c9-57a6-42c3-82e0-7b44f8a2a1df/Production/?company=Meritage%20Health%20Plan',
  azureHealthcare: 'https://azure.microsoft.com/en-us/industries/healthcare',
}
