export default [
  '26.11.2020',
  '27.11.2020',
  '24.12.2020',
  '25.12.2020',
  '01.01.2021', // NEW YEAR'S DAY
  '18.01.2021', // MARTIN LUTHER KING JR'S BIRTHDAY
  '15.02.2021', // PRESIDENT'S DAY
  '31.05.2021', // MEMORIAL DAY
  '05.07.2021', // INDEPENDENCE DAY
  '06.09.2021', // LABOR DAY
  '25.11.2021', // THANKSGIVING DAY
  '26.11.2021', // DAY AFTER THANKSGIVING
  '24.12.2021', // CHRISTMAS DAY
  '31.12.2021', // NEW YEAR'S DAY
  '17.01.2022', // Martin Luther King Jr.’s Birthday
  '21.02.2022', // President’s Day
  '30.05.2022', // Memorial Day
  '20.06.2022', // Juneteenth
  '04.07.2022', // Independence Day
  '05.09.2022', // Labor Day
  '24.11.2022', // Thanksgiving Day
  '25.11.2022', // Day After Thanksgiving
  '23.12.2022', // Christmas Eve
  '26.12.2022', // Christmas Day
  // 2023
  '02.01.2023', // New Year's Day
  '29.05.2023', // New Year's Day
  '04.09.2023', // Labor Day
  '25.12.2023', // Christmas Day
  '16.01.2023', // Martin Luther King Jr's Birthday
  '19.06.2023', // Juneteenth
  '23.11.2023', // Thanksgiving Day
  '20.02.2023', // Presidents Day
  '04.07.2023', // Independence Day
  '24.11.2023', // Day after Thanksgiving
]
