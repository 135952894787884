import React from 'react'
import MomentUtils from '@date-io/moment'
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import { Router, Switch } from 'react-router-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Route } from 'components'
import { Route as RouteProps } from 'types'
import { Provider } from 'react-redux'
import { AnyAction, Store } from 'redux'
import { createBrowserHistory } from 'history'
import { theme } from 'config'
import googleAnalytics from 'config/google-analytics'

const history = createBrowserHistory()
history.listen(location => googleAnalytics.setPage(location.pathname))

interface AppProps {
  routes: RouteProps[]
  store: Store<any, AnyAction>
}

const App = ({ routes, store }: AppProps) => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <CssBaseline />
        <Router history={history}>
          <Switch>
            {routes.map(route => (
              <Route key={route.path?.toString()} {...route} />
            ))}
            <Route component={() => <div>Not found</div>} />
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </Provider>
)

export default App
