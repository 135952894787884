import { styled, Grid } from '@material-ui/core'
import { TOP_BAR_HEIGHT } from './TopBar/TopBar.style'

export const Wrapper = styled(Grid)({
  width: '100%',
  height: '100%',
})

export const Content = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflowX: 'hidden',
  overflowY: 'scroll',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

export const Container = styled('div')({
  flexGrow: 1,
  display: 'flex',
  width: '100%',
  overflow: 'auto',
  height: `calc(100% - ${TOP_BAR_HEIGHT}px)`,
})
