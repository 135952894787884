import { asyncThunkFactory } from 'store/helpers'
import { WorkStatus } from 'types'
import { put } from 'utils'
import { NAMESPACE } from '../constants'

export interface UpdateScheduleDto {
  type: WorkStatus
}

export interface UpdateScheduleParams {
  scheduleId: number
  body: UpdateScheduleDto[]
}

export default asyncThunkFactory<UpdateScheduleDto, UpdateScheduleParams>(
  NAMESPACE,
  'updateSchedule',
  async params => {
    const path = ['schedules', params.scheduleId].join('/')
    const response = await put(path, { data: params.body })
    return response.data
  }
)
