import { asyncThunkFactory } from 'store/helpers'
import { get } from 'utils'
import { NAMESPACE } from '../constants'

export interface LatestWellnessCalendarDto {
  id: number
  name: string
  path: string
  hidden: boolean
}

export default asyncThunkFactory<LatestWellnessCalendarDto, unknown>(
  NAMESPACE,
  'fetchLatestWellnessCalendar',
  async params => {
    const path = ['uploads', 'wellness-calendars', 'latest'].join('/')
    const response = await get(path)
    return response.data
  }
)
