import { asyncThunkFactory } from 'store/helpers'
import { WorkStatus } from 'types'
import { get } from 'utils'
import { NAMESPACE } from '../constants'

export interface FetchUsersDto {
  data: UserDto[]
  take: number
  skip: number
  count: number
}

export interface UserDto {
  id: string
  name: string
  firstName: string
  lastName: string
  email: string
  imageUrl: string
  departments: { id: string; name: string }[]
  schedules: { id: number; type: WorkStatus; date: string }[]
}

export interface FetchUsersParams {
  query: {
    take?: number
    skip?: number
    order?: string
    orderBy?: string
    search?: string
    department?: string
    startDate?: string
    endDate?: string
  }
}

export default asyncThunkFactory<FetchUsersDto, FetchUsersParams>(
  NAMESPACE,
  'fetchUsers',
  async params => {
    const path = ['users'].join('/')
    const response = await get(path, { params: params.query })
    return response.data
  }
)
