import React, { FunctionComponent } from 'react'
import { Wrapper, Title, Link } from './LeftMenuItem.style'
import { MenuItem, MenuItemType } from '../MenuItems'
import { getIconStyles } from '../LeftMenu.style'
import LeftMenuDropdown from '../LeftMenuDropdown/LeftMenuDropdown'

export interface LeftMenuItemProps {
  onClick?: (path: string) => void
  isActiveHandler: (paths: string[]) => boolean
  item: MenuItem
  color?: string
  barColor?: string
}

const LeftMenuItem: FunctionComponent<LeftMenuItemProps> = ({
  isActiveHandler,
  onClick,
  item,
  color,
  barColor,
}) => {
  if (item.path && item.type === MenuItemType.EXTERNAL)
    return (
      <Link href={item.path} rel={'noopener noreferrer'} target={'_blank'}>
        <Wrapper style={{ color }}>
          <Title>
            {item.icon && <item.icon style={getIconStyles()} />}
            {item.title}
          </Title>
        </Wrapper>
      </Link>
    )

  if (item.path && item.type === MenuItemType.APP)
    return (
      <Wrapper
        style={{
          active: Number(isActiveHandler([item.path])),
          color,
        }}
        onClick={() => onClick && item.path && onClick(item.path)}
      >
        <Title style={{ active: Number(isActiveHandler([item.path])) }}>
          {item.icon && <item.icon style={getIconStyles()} />}
          {item.title}
        </Title>
      </Wrapper>
    )

  if (item.type === MenuItemType.DROPDOWN)
    return (
      <LeftMenuDropdown
        isActiveHandler={isActiveHandler}
        item={item}
        onItemClick={onClick}
        color={item.color}
        borderColor={item.color}
      />
    )

  if (item.path && item.type === MenuItemType.PDF)
    return (
      <Wrapper
        onClick={() => window.open(item.path, '_blank')}
        style={{ color }}
      >
        <Title>
          {item.icon && <item.icon style={getIconStyles()} />}
          {item.title}
        </Title>
      </Wrapper>
    )

  return null
}

export default LeftMenuItem
