import { styled, WithTheme } from '@material-ui/core'
import styledComponent, { keyframes } from 'styled-components'
import LoaderIcon from 'icons/Loader'
import { LoaderSize } from './Spinner'

interface WrapperProps extends WithTheme {
  style?: {
    backgroundColor?: string
  }
}

const spin = keyframes`
  0% {
    animation-timing-function: ease-in;
    transform: rotate( 0deg ) scale( 1 );
  }
  25% {
    animation-timing-function: ease-out;
    transform: rotate( -180deg ) scale( 1.25 );
  }
  75%, 100% {
    transform: rotate( -360deg ) scale( 1 );
  }
`

const getLoaderSize = (size: LoaderSize) => {
  if (size === 'small') return 30
  if (size === 'medium') return 48
  return 64
}

export const Wrapper = styled('div')(({ style }: WrapperProps) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: style?.backgroundColor,
  zIndex: 2000,
}))

export const Loader = styledComponent(LoaderIcon)`
  animation-iteration-count: infinite;
  height: ${(p: { size: LoaderSize }) => getLoaderSize(p.size)}px;
  width: ${(p: { size: LoaderSize }) => getLoaderSize(p.size)}px;
  animation-name: ${spin};
  animation-duration: 1.6s;
  animation-timing-function: ease-in-out;
` as any
