import { createSlice } from '@reduxjs/toolkit'
import { UIState } from './types'
import {
  WorkStatus,
  SummaryModal,
  CalendarDate,
  SortingOrder,
  SortingMethod,
} from 'types'
import { moment, formatDate } from 'utils'
import { REDUCER_NAME } from './config'

const initialState: UIState = {
  isLoading: false,
  calendarModalType: undefined,
  summaryModal: {
    isOpen: false,
    type: undefined,
    title: '',
    department: undefined,
    month: formatDate(moment()),
  },
  filters: {
    calendar: {
      department: undefined,
      statusType: undefined,
    },
    profile: {
      statusType: undefined,
    },
  },
  sort: {
    by: undefined,
    order: undefined,
  },
  pagination: {
    pageNumber: 1,
  },
}

const slice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    toggleSummaryModal: state => {
      state.summaryModal.isOpen = !state.summaryModal.isOpen
    },
    setCalendarModalType: (
      state,
      action: { payload: UIState['calendarModalType'] }
    ) => {
      state.calendarModalType = action.payload
    },
    clearCalendarModalType: state => {
      state.calendarModalType = undefined
    },
    setSummaryModalTitle: (state, action: { payload: string }) => {
      state.summaryModal.title = action.payload
    },
    resetSummaryModalTitle: state => {
      state.summaryModal.title = ''
    },
    setSummaryModalType: (state, action: { payload: SummaryModal }) => {
      state.summaryModal.type = action.payload
    },
    resetSummaryModalType: state => {
      state.summaryModal.type = undefined
    },
    setSummaryModalMonth: (state, action: { payload: CalendarDate }) => {
      state.summaryModal.month = action.payload
    },
    resetSummaryModalMonth: state => {
      state.summaryModal.month = formatDate(moment())
    },
    setSummaryModalDepartment: (state, action: { payload: string }) => {
      state.summaryModal.department = action.payload.replace(
        /Department|department/gi,
        ''
      )
    },
    resetSummaryModal: state => {
      state.summaryModal = initialState.summaryModal
    },
    resetSummaryModalDepartment: state => {
      state.summaryModal.department = undefined
    },
    setDepartmentFilter: (state, action: { payload: string | undefined }) => {
      state.filters.calendar.department = action.payload
    },
    setStatusFilter: (state, action: { payload: WorkStatus | undefined }) => {
      state.filters.calendar.statusType = action.payload
    },
    setProfileStatusFilter: (
      state,
      action: { payload: WorkStatus | undefined }
    ) => {
      state.filters.profile.statusType = action.payload
    },
    resetProfileFilters: state => {
      state.filters.profile = initialState.filters.profile
    },
    setSorting: (
      state,
      action: { payload: { order: SortingOrder; by: SortingMethod } }
    ) => {
      state.sort = action.payload
    },
    resetSorting: state => {
      state.sort = { order: undefined, by: undefined }
    },
    setPageNumber: (state, action: { payload: number }) => {
      state.pagination.pageNumber = action.payload
    },
    resetCalendar: state => {
      state.pagination.pageNumber = 1
      state.summaryModal.isOpen = false
      state.summaryModal.month = formatDate(moment())
      state.sort.by = undefined
      state.sort.order = undefined
      state.filters.calendar.department = undefined
      state.filters.calendar.statusType = undefined
    },
  },
})

export const actions = slice.actions

export default slice
