import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { schedules as schedulesEndpoints, users } from 'config'
import { isArray } from 'utils'
import { get, post, put } from 'utils/api'
import { Schedule, ScheduleUpdateData } from './types'

export const REDUCER_NAME = 'user'

export const fetchCurrentUser = createAsyncThunk<AxiosResponse>(
  `${REDUCER_NAME}/fetchCurrentUser`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await get(users.CURRENT_USER)
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)

export const addSchedules = createAsyncThunk<
  AxiosResponse | Schedule[],
  Schedule | Schedule[]
>(`${REDUCER_NAME}/addSchedules`, async (data, { rejectWithValue }) => {
  try {
    const schedules = isArray(data) ? data : [data]
    const newSchedules = await post(schedulesEndpoints.BASE, {
      data: {
        schedules,
      },
    })
    return newSchedules.data
  } catch (err) {
    if (!err.response) {
      throw err
    }
    return rejectWithValue(err.response)
  }
})

export const updateSchedules = createAsyncThunk<
  AxiosResponse | ScheduleUpdateData,
  ScheduleUpdateData
>(`${REDUCER_NAME}/updateSchedules`, async (data, { rejectWithValue }) => {
  try {
    const { id, type } = data
    await put(schedulesEndpoints.UPDATE_SCHEDULES(id), {
      data: {
        type,
      },
    })
    return data
  } catch (err) {
    if (!err.response) {
      throw err
    }
    return rejectWithValue(err.response)
  }
})
