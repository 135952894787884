import { Button as BaseButton, styled } from '@material-ui/core'

export const Button = styled(BaseButton)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 'bold',
  color: theme.palette.common.white,
}))

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  height: 46,
  marginRight: theme.spacing(3),
}))
