import { GridProps, List, useTheme } from '@material-ui/core'
import GroupIcon from '@material-ui/icons/Group'
import TrackingIcon from '@material-ui/icons/TrackChanges'
import OverflowFade from 'components/OverflowFade'
import ScrollContainer from 'components/ScrollContainer'
import { createWellnessCalendarUrl } from 'config/api-assets'
import { palette } from 'config/theme'
import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import fetchCategoriesAction from 'store/articles/actions/fetch-categories.action'
import { RootState } from 'store/store'
import fetchLatestWellnessCalendarAction from 'store/uploads/actions/fetch-latest-wellness-calendar.action'
import * as uploadsSelectors from 'store/uploads/selectors'
import { CategoryDto } from 'types'
import { Wrapper } from './LeftMenu.style'
import LeftMenuItem from './LeftMenuItem'
import { MenuItem, menuItems, MenuItemType } from './MenuItems'

const createDepartmentsMenuItem = (
  categories: CategoryDto[] = [],
  color: string
): MenuItem => ({
  icon: GroupIcon,
  title: 'Departments',
  path: '/some-path',
  type: MenuItemType.DROPDOWN,
  color,
  dropdownItems: categories.map(category => ({
    path: `/departments/${category.id}`,
    title: category.name,
    type: MenuItemType.APP,
    barColor: color,
  })),
})

const createWellnessMenuItem = (
  latestWellnessCalendarPath?: string,
  displayCalendar?: boolean
): MenuItem => {
  const menuItem: MenuItem = {
    icon: TrackingIcon,
    type: MenuItemType.DROPDOWN,
    title: 'Wellness',
    dropdownItems: [
      {
        path:
          'https://datastudio.google.com/reporting/f469ddf6-38d4-4815-9ee3-81a8f18df021/page/12j2C',
        title: 'Activity Tracker',
        type: MenuItemType.EXTERNAL,
        barColor: palette.primary.main,
      },
    ],
  }

  if (displayCalendar && latestWellnessCalendarPath) {
    menuItem.dropdownItems?.push({
      path: createWellnessCalendarUrl(latestWellnessCalendarPath),
      title: 'Calendar',
      type: MenuItemType.EXTERNAL,
      barColor: palette.primary.main,
    })
  }

  return menuItem
}

const containsCurrentPath = (paths: string[] = [], currentPath: string) => {
  if (paths.length <= 0) return false

  for (const path of paths) {
    if (path && currentPath === path) return true
  }

  return false
}

const LeftMenu: FunctionComponent<GridProps> = props => {
  const history = useHistory()
  const theme = useTheme()

  const dispatch = useDispatch()

  const categoriesState = useSelector(
    (state: RootState) => state.articles.categories
  )

  const latestWellnessCalendar = useSelector(
    uploadsSelectors.latestWellnessCalendar
  )

  useEffect(() => {
    dispatch(fetchLatestWellnessCalendarAction({}))
    dispatch(
      fetchCategoriesAction({
        query: {
          excludeDashboard: true,
          order: 'ASC',
          orderBy: 'createdAt',
        },
      })
    )
  }, [dispatch])

  const handleMenuItemClick = (path: string) => history.replace(path)

  menuItems.wellness = createWellnessMenuItem(
    latestWellnessCalendar.data?.path,
    !!latestWellnessCalendar.data
  )

  const mainMenuItems = [
    ...Object.values(menuItems),
    createDepartmentsMenuItem(
      categoriesState.data,
      theme.palette.secondary.light
    ),
  ].map(item => (
    <LeftMenuItem
      key={`item-${item.title}`}
      onClick={handleMenuItemClick}
      isActiveHandler={paths =>
        containsCurrentPath(paths, history.location.pathname)
      }
      item={item}
    />
  ))

  return (
    <Wrapper direction="column" container item {...props}>
      <ScrollContainer>
        <List>{mainMenuItems}</List>
        <OverflowFade />
      </ScrollContainer>
    </Wrapper>
  )
}

export default LeftMenu
