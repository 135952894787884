import { createSlice } from '@reduxjs/toolkit'
import {
  defaultListedApiResource,
  handleApiResourceBasePending,
  handleListedApiResourceFulfilled,
  handleListedApiResourceRejected,
  ListedApiResource,
} from 'store/helpers'
import { WorkStatus } from 'types'
import { moment } from 'utils'
import { fetchDayStats } from './actions'
import createSchedulesAction from './actions/create-schedules.action'
import fetchSchedulesAction, {
  ScheduleDto,
} from './actions/fetch-schedules.action'
import updateScheduleAction from './actions/update-schedule.action'
import { NAMESPACE } from './constants'

export interface SchedulesState {
  stats: Record<WorkStatus, Stats>
  isLoading: boolean
  schedules: ListedApiResource<ScheduleDto>
  schedulesManagement: {
    lastUserSchedulesUpdate: string
    isLoading: boolean
  }
}

export interface Stats {
  count: number
  percentage: number
}

const initialState: SchedulesState = {
  isLoading: false,
  schedules: defaultListedApiResource(),
  schedulesManagement: {
    lastUserSchedulesUpdate: moment().format(),
    isLoading: false,
  },
  stats: {
    off: {
      count: 0,
      percentage: 0,
    },
    // office: {
    //   count: 0,
    //   percentage: 0,
    // },
    fresno: {
      count: 0,
      percentage: 0,
    },
    petaluma: {
      count: 0,
      percentage: 0,
    },
    remote: {
      count: 0,
      percentage: 0,
    },
    holiday: {
      count: 0,
      percentage: 0,
    },
    unfilled: {
      count: 0,
      percentage: 0,
    },
  },
}

const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(fetchDayStats.pending, state => {
        state.isLoading = true
      })
      .addCase(fetchDayStats.fulfilled, (state, action) => {
        state.stats = action.payload.data
      })
      .addCase(fetchDayStats.rejected, state => {
        state.isLoading = false
      })
      .addCase(fetchSchedulesAction.pending, state =>
        handleApiResourceBasePending(state.schedules)
      )
      .addCase(fetchSchedulesAction.fulfilled, (state, action) =>
        handleListedApiResourceFulfilled(state.schedules, action.payload.data)
      )
      .addCase(fetchSchedulesAction.rejected, (state, action) =>
        handleListedApiResourceRejected(state.schedules, action.error)
      )
      .addCase(createSchedulesAction.pending, state => {
        state.schedulesManagement.isLoading = true
      })
      .addCase(createSchedulesAction.fulfilled, (state, action) => {
        state.schedulesManagement.isLoading = false
        state.schedulesManagement.lastUserSchedulesUpdate = moment().format()
      })
      .addCase(createSchedulesAction.rejected, (state, action) => {
        state.schedulesManagement.isLoading = false
      })
      .addCase(updateScheduleAction.pending, state => {
        state.schedulesManagement.isLoading = true
      })
      .addCase(updateScheduleAction.fulfilled, (state, action) => {
        state.schedulesManagement.isLoading = false
        state.schedulesManagement.lastUserSchedulesUpdate = moment().format()
      })
      .addCase(updateScheduleAction.rejected, (state, action) => {
        state.schedulesManagement.isLoading = false
      }),
})

export const actions = slice.actions

export default slice
