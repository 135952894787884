import ReactGA from 'react-ga'

const trackingId = process.env.REACT_APP_GA_TRACKING_ID || ''

const initialize = () => {
  ReactGA.initialize(trackingId, { debug: false })
  ReactGA.pageview(window.location.pathname + window.location.search)
}

const setUserId = (userId?: string) => ReactGA.set({ userId })

const clearUserId = () => ReactGA.set({ userId: undefined })

const setPage = (page: string) => {
  ReactGA.set({ page })
  ReactGA.pageview(page)
}

const instance = ReactGA

export default {
  initialize,
  setUserId,
  clearUserId,
  setPage,
  instance,
}
