import { Route } from 'types'
import { Loadable } from 'components'

export const paths = {
  root: '/profile',
}

export const routes: Route[] = [
  {
    private: true,
    path: paths.root,
    exact: true,
    component: Loadable({
      component: () => import('./components/Profile'),
    }),
  },
]

export default routes
