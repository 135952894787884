import { asyncThunkFactory } from 'store/helpers'
import { get } from 'utils'
import { NAMESPACE } from '../constants'

export interface GetUsersBasicDto {
  data: UserBasicDto[]
  take: number
  skip: number
  count: number
}

export interface UserBasicDto {
  id: string
  name: string
  firstName: string
  lastName: string
  imgUrl: string
  departments: { id: number; name: number }[]
}

export interface FetchUsersBasicParams {
  query: {
    take?: number
    skip?: number
    order?: string
    orderBy?: string
    department?: string
    search?: string
  }
}

export default asyncThunkFactory<GetUsersBasicDto, FetchUsersBasicParams>(
  NAMESPACE,
  'fetchUsersBasic',
  async params => {
    const path = ['users', 'basic'].join('/')
    const response = await get(path, { params: params.query })
    return response.data
  }
)
