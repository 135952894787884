import { asyncThunkFactory } from 'store/helpers'
import { ArticleType } from 'types'
import { get } from 'utils'
import { NAMESPACE } from '../constants'

export interface CategoryArticleDto {
  id: string
  title: string
  description: string
  thumbnail: string
  type: ArticleType
  url: string
  pdf: string
  content: string
}

export interface FetchCategoryArticlesParams {
  categoryId: string
  query: {
    order?: 'ASC' | 'DESC'
    orderBy?: string
  }
}

export default asyncThunkFactory<
  CategoryArticleDto[],
  FetchCategoryArticlesParams
>(NAMESPACE, 'fetchCategoryArticles', async params => {
  const response = await get(`categories/${params.categoryId}/articles`, {
    params: params.query,
  })
  return response.data.data
})
