import { Route } from 'types'
import { Loadable } from 'components'

export const paths = {
  root: '/',
  teams: '/teams',
}

export const routes: Route[] = [
  {
    private: true,
    path: paths.root,
    exact: false,
    component: Loadable({
      component: () => import('./components/Dashboard'),
    }),
  },
  {
    private: true,
    path: paths.teams,
    exact: true,
    component: Loadable({
      component: () => import('./components/Dashboard'),
    }),
  },
]

export default routes
