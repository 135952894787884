import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  opacity: 0;
  height: 100px;
  width: 100%;
  transition: opacity 300ms;
  pointer-events: none;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
`
