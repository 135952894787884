import React, { FunctionComponent } from 'react'

import { Wrapper } from './ScrollContainer.style'

const ScrollContainer: FunctionComponent = ({ children }) => {
  return (
    <Wrapper hideScrollbars={false} activationDistance={10}>
      {children}
    </Wrapper>
  )
}

export default ScrollContainer
