import { Module } from 'types'

import profile from './profile'
import login from './login'
import dashboard from './dashboard'
import digitalCalendar from './digitalCalendar'
import department from './department'
import search from './search'

export default [
  search,
  department,
  login,
  digitalCalendar,
  profile,
  dashboard,
] as Module[]
