import { InputBase } from '@material-ui/core'
import React from 'react'
import { InputWrapper, SearchIcon } from './SerachInput.style'

interface SerachInputProps {
  value: string
  placeholder?: string
  onValueChange?: (value: string) => void
  onKeyDown?: (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
}

const SearchInput: React.FC<SerachInputProps> = ({
  value,
  onValueChange,
  onKeyDown,
  placeholder = '',
}) => {
  return (
    <InputWrapper>
      <SearchIcon />
      <InputBase
        tabIndex={0}
        value={value}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search' }}
        onChange={event => onValueChange && onValueChange(event.target.value)}
        onKeyDown={onKeyDown}
      />
    </InputWrapper>
  )
}

export default SearchInput
