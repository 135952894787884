/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState } from 'react'
import { Wrapper } from './Modal.style'
import { Grid, Fade } from '@material-ui/core'

export const DEFAULT_TIMEOUT = 50

interface ModalProps {
  open?: boolean
}

const Modal: FunctionComponent<ModalProps> = ({ children, open = false }) => {
  const [showModal, setShowModal] = useState(open)
  const enableModal = () => setShowModal(true)
  let timeout: number
  const disableModal = () => {
    timeout = setTimeout(() => setShowModal(open), DEFAULT_TIMEOUT)
  }
  useEffect(() => (open ? enableModal() : disableModal()), [open])
  useEffect(() => {
    clearTimeout(timeout)
  }, [])

  return showModal ? (
    <Fade in={open} timeout={{ enter: DEFAULT_TIMEOUT, exit: DEFAULT_TIMEOUT }}>
      <Wrapper container alignItems="center" justify="center">
        <Grid>{children}</Grid>
      </Wrapper>
    </Fade>
  ) : null
}
export default Modal
