export default {
  off: {
    main: '#EA5C52',
    light: '#F8E5E1',
  },
  office: {
    main: '#22638D',
    light: '#E1EBF8',
  },
  fresno: {
    main: '#22638D',
    light: '#E1EBF8',
  },
  petaluma: {
    main: '#22638D',
    light: '#E1EBF8',
  },
  remote: {
    main: '#19AD79',
    light: '#DCFFF2',
  },
  holiday: {
    main: '#ff8566',
    light: '#ffc2b3',
  },
  unfilled: {
    main: '#696666',
    light: '#ffffff',
  },
  timeliine: {
    red: '#CB1642',
    grey: '#5B6E78',
  },
  calendar: {
    grey: '#fcfcfc',
  },
}
