import { Module } from 'types'
import { MODULE_NAME, MODULE_TITLE } from './strings'
import { routes, paths } from './routing'

const moduleConfig: Module = {
  routes,
  private: true,
  title: MODULE_TITLE,
  name: MODULE_NAME,
  path: paths.root,
}

export default moduleConfig
