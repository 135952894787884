import { styled } from '@material-ui/core'
import BaseScrollContainer from 'react-indiana-drag-scroll'

export const Wrapper = styled(BaseScrollContainer)(() => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}))
