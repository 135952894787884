import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

const Icon = (props: SvgIconProps) => (
  <SvgIcon width="19" height="19" viewBox="0 0 19 19" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1 7.6H1.9V4.75H17.1V7.6ZM12.35 13.3H8.55V9.5H12.35V13.3ZM6.65 13.3H2.85V9.5H6.65V13.3ZM17.1 2.85H15.2V0H13.3V2.85H5.7V0H3.8V2.85H1.9C0.85215 2.85 0 3.7031 0 4.75V17.1C0 18.1479 0.85215 19 1.9 19H17.1C18.1479 19 19 18.1479 19 17.1V4.75C19 3.7031 18.1479 2.85 17.1 2.85Z"
    />
  </SvgIcon>
)

export default Icon
