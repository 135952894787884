import React, { FunctionComponent } from 'react'
import { Wrapper, Loader } from './Spinner.style'

export type LoaderSize = 'small' | 'medium' | 'big'

interface SpinnerProps {
  size?: LoaderSize
  white?: boolean
  noBackground?: boolean
}

const Spinner: FunctionComponent<SpinnerProps> = ({
  size = 'small',
  white = false,
  noBackground = false,
}) => {
  const backgroundColor = noBackground
    ? 'rgba(0,0,0,0)'
    : white
    ? 'rgba(255, 255, 255, 0.85)'
    : 'rgba(0, 42, 95, 0.2)'

  return (
    <Wrapper style={{ backgroundColor }}>
      <Loader size={size} />
    </Wrapper>
  )
}

export default Spinner
