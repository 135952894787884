import { getAvatarUrl } from 'config/api-assets'
import { MMNLogo } from 'images'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/user/actions/logout.action'
import * as userSelectors from 'store/user/selectors'
import Search from './Search'
import {
  AppBar,
  Avatar,
  Content,
  Logo,
  LogoContainer,
  LogoutButton,
  SearchWrapper,
  Title,
  Toolbar,
} from './TopBar.style'

interface TopBarProps {
  title?: string
}

const TopBar = ({ title = '' }: TopBarProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const currentUser = useSelector(userSelectors.currentUser)
  return (
    <AppBar position="static">
      <Toolbar>
        <LogoContainer>
          <Logo src={MMNLogo} />
        </LogoContainer>
        <Content>
          <Title style={{ fontWeight: 100 }}>{title}</Title>
          <SearchWrapper>
            <Search />
            <LogoutButton
              style={{ marginLeft: 8 }}
              color="secondary"
              variant="contained"
              onClick={() => dispatch(logout())}
            >
              {t('LOGOUT')}
            </LogoutButton>
            <Avatar
              src={
                currentUser.data?.imageUrl &&
                getAvatarUrl(currentUser.data.imageUrl)
              }
            />
          </SearchWrapper>
        </Content>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
