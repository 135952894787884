import {
  Avatar as AvatarBase,
  Grid,
  WithTheme,
  styled,
} from '@material-ui/core'
import { HeaderProps } from './Header'

interface Props extends WithTheme {
  styled?: HeaderProps
}

export const Wrapper = styled(Grid)(({ styled = {}, theme }: Props) => ({
  boxShadow: 'none',
  backgroundImage: styled.noBackground
    ? 'none'
    : `linear-gradient(rgba(8,38,76,0.8), rgba(8,38,76,0.8)), ${
        `url('${styled.url}')` || ''
      }`,
  backgroundColor: theme.palette.background.paper,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
}))

export const Avatar = styled(AvatarBase)(({ theme }) => ({
  boxSizing: 'border-box',
  border: `2px solid ${theme.palette.info.dark}`,
}))
