import { Module } from 'types'
import { routes, paths } from './routing'

const moduleConfig: Module = {
  routes,
  title: 'search',
  name: 'search',
  path: paths.root,
  private: true,
}

export default moduleConfig
