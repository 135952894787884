import { styled, Grid } from '@material-ui/core'

export const Wrapper = styled(Grid)(({ theme }) => ({
  minWidth: 400,
  width: '100%',
  height: 46,
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  boxSizing: 'border-box',
  border: `1px solid ${theme.palette.info.main}`,
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(0, 2),
}))

export const InputWrapper = styled(Grid)(({ theme }) => ({
  height: '100%',
  flexGrow: 1,
  color: theme.palette.text.secondary,
  boxSizing: 'border-box',
  '& input::placeholder': {
    color: theme.palette.text.secondary,
    opacity: 1,
  },
}))
