import config from './index'

export const getAvatarUrl = (fileName: string) =>
  `${config.API_ASSETS_URL}/avatars/${fileName}`

export const getPdfUrl = (fileName: string) =>
  `${config.API_ASSETS_URL}/pdfs/${fileName}`

export const getThumbnailUrl = (fileName: string) =>
  `${config.API_ASSETS_URL}/thumbnails/${fileName}`

export const createWellnessCalendarUrl = (name: string) =>
  `${config.API_ASSETS_URL}/wellness-calendars/${name}`
