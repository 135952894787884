import { asyncThunkFactory } from 'store/helpers'
import { WorkStatus } from 'types'
import { get } from 'utils'
import { NAMESPACE } from '../constants'

export interface GetSchedulesDto {
  data: ScheduleDto[]
}

export interface ScheduleDto {
  id: number
  type: WorkStatus
  date: string
  location?: string
}

export interface FetchUserSchedulesParams {
  query: {
    startDate?: string
    endDate?: string
  }
}

export default asyncThunkFactory<GetSchedulesDto, FetchUserSchedulesParams>(
  NAMESPACE,
  'fetchSchedules',
  async params => {
    const path = 'schedules'
    const response = await get(path, { query: params.query })
    return response.data
  }
)
