import { loginRequest, msalConfig } from '../../../config/authConfig'
import * as msal from '@azure/msal-browser'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { NAMESPACE } from '../constants'

const msalObject = new msal.PublicClientApplication(msalConfig)

export default createAsyncThunk<string>(
  `${NAMESPACE}/login`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await msalObject.loginPopup(loginRequest)
      return response.accessToken
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
)
