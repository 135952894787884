import { styled, Grid, Typography, WithTheme } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

interface WrapperProps extends WithTheme {
  style?: {
    color?: string
  }
}

export const Wrapper = styled('div')(({ theme, style }: WrapperProps) => ({
  display: 'flex',
  flexDirection: 'column',
}))

interface HeadingProps extends WithTheme {
  styled?: {
    active?: boolean
    borderColor?: string
    color?: string
  }
}

export const Heading = styled('div')(({ theme, styled }: HeadingProps) => ({
  display: 'flex',
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1.5),
  padding: theme.spacing(1, 1.5),
  cursor: 'pointer',
  boxSizing: 'border-box',
  borderColor: styled?.borderColor,
  borderWidth: styled?.borderColor ? theme.spacing(0.25) : 0,
  borderStyle: 'solid',
  color: styled?.active
    ? theme.palette.common.white
    : styled?.color
    ? styled.color
    : theme.palette.primary.main,
  backgroundColor: styled?.active
    ? theme.palette.primary.main
    : theme.palette.background.paper,
  '&:hover': {
    backgroundColor: styled?.active
      ? theme.palette.primary.main
      : theme.palette.primary.dark,
    '& *': {
      color: theme.palette.common.white,
    },
  },
}))

export const Link = styled('a')(({ theme }) => ({
  textDecoration: 'none',
}))

export const Icon = styled(Grid)({
  flexGrow: 0,
})

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 100,
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
}))

interface DropdownIconProps extends WithTheme {
  styled: {
    expanded: boolean
  }
}

export const DropdownIcon = styled(ArrowDropDownIcon)(
  ({ theme, styled: { expanded } }: DropdownIconProps) => ({
    width: 40,
    transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
  })
)

interface ListProps extends WithTheme {
  styled: {
    display: boolean
  }
}

export const List = styled('div')(
  ({ theme, styled: { display } }: ListProps) => ({
    display: display ? 'flex' : 'none',
    marginLeft: 20,
    flexDirection: 'column',
  })
)
