import {
  Avatar as AvatarBase,
  WithTheme,
  List as BaseList,
  Collapse as BaseCollapse,
  Typography,
  styled,
} from '@material-ui/core'
import { BUTTON_BASE_HEIGHT } from 'config/theme'

interface IconProps extends WithTheme {
  open: boolean
  src: string
}

interface InputWrapperProps extends WithTheme {
  value?: any
}

export const Avatar = styled(AvatarBase)(({ theme }) => ({
  boxSizing: 'border-box',
  border: `2px solid ${theme.palette.info.dark}`,
}))

export const Collapse = styled(BaseCollapse)(({ theme }) => ({
  position: 'absolute',
  top: BUTTON_BASE_HEIGHT + 5,
  zIndex: 999999,
  width: '100%',
}))

export const List = styled(BaseList)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  boxShadow: theme.shadows[2],
  padding: theme.spacing(2, 0),
}))

export const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.info.main}`,
}))

export const Icon = styled('img')(({ theme, open }: IconProps) => ({
  transform: `rotateZ(${open ? 180 : 0}deg)`,
  transition: 'transform .3s',
}))

export const InputWrapper = styled('div')(
  ({ theme, value }: InputWrapperProps) => ({
    width: '100%',
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    '& *': {
      cursor: 'pointer !important',
      paddingLeft: 0,
    },
    '& input': {
      paddingLeft: theme.spacing(2.5),
      fontWeight: value ? 'bold' : 'normal',
    },
    '& input::placeholder': {
      color: theme.palette.text.secondary,
      opacity: 1,
    },
  })
)

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: 17,
  zIndex: 100,
  lineHeight: '19px',
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
  top: -19,
  left: 0,
  position: 'absolute',
}))

export const DisplayValueWrapper = styled('div')(({ theme }: WithTheme) => ({
  display: 'flex',
  fontSize: 16,
  padding: theme.spacing(1.5, 2.5),
  fontWeight: 'bold',
}))

export const ValueDisplay = styled('div')(() => ({
  flexGrow: 1,
}))
