import { asyncThunkFactory } from 'store/helpers'
import { NAMESPACE } from 'store/user/constants'
import { get } from 'utils'

export interface CategoryDto {
  id: string
  name: string
}

export interface PaginatedResponse<T> {
  data: T[]
  count: number
  take: number
  skip: number
}

export interface FetchCategoriesParams {
  query: {
    excludeDashboard?: boolean
    order?: 'ASC' | 'DESC'
    orderBy?: string
  }
}

export default asyncThunkFactory<CategoryDto[], FetchCategoriesParams>(
  NAMESPACE,
  'fetchCategories',
  async params => {
    const response = await get('categories', { params: params.query })
    return response.data.data
  }
)
