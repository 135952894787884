import { AnyReducer, Module, Route } from 'types'
import { mergeArrays } from 'utils'

export interface Reducers {
  [key: string]: AnyReducer
}

export default class AppConfig {
  modules: Module[] = []
  routes: Route[] = []
  reducers: Reducers = {}

  constructor(modules: Module[]) {
    this.modules = modules
    this.routes = this.getConfigArray<Route>('routes')
  }

  private getConfigArray = <T>(key: keyof Module) =>
    this.modules.reduce(
      (configs, module) => mergeArrays(configs, module[key] as T[]),
      [] as T[]
    )
}
