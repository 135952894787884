import { Route } from 'types'
import { Loadable } from 'components'

export const paths = {
  root: '/search/:searchString',
}

export const routes: Route[] = [
  {
    private: true,
    path: paths.root,
    exact: true,
    component: Loadable({
      component: () => import('./components/SearchView/SearchView'),
    }),
  },
]

export default routes
