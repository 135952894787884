import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { schedules } from 'config'
import { get } from 'utils/api'
import { NAMESPACE } from './constants'
import { StatsParams } from './types'

export const fetchDayStats = createAsyncThunk<AxiosResponse, StatsParams>(
  `${NAMESPACE}/fetchStats`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await get(schedules.DAY_STATS, { params })
      return response
    } catch (err) {
      if (!err.response) throw err
      return rejectWithValue(err.response)
    }
  }
)

export const fetchUserStats = createAsyncThunk<AxiosResponse, StatsParams>(
  `${NAMESPACE}/fetchStats`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await get(schedules.USER_STATS, { params })
      return response
    } catch (err) {
      if (!err.response) throw err
      return rejectWithValue(err.response)
    }
  }
)

export const fetchMonthlyStats = createAsyncThunk<AxiosResponse, StatsParams>(
  `${NAMESPACE}/fetchStats`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await get(schedules.USERS_STATS, { params })
      return response
    } catch (err) {
      if (!err.response) throw err

      return rejectWithValue(err.response)
    }
  }
)
