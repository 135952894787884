import axios, { Method } from 'axios'
import config from 'config'

const getCommonHeaders = (settings?: any) => {
  const token = localStorage.getItem(config.APP_TOKEN_KEY)
  return {
    ...(settings?.disableAuthorization
      ? {}
      : { Authorization: `Bearer ${token}` }),
    'Content-Type': 'application/json',
  }
}

const getRequest = async (
  endpoint: string,
  settings = { headers: {}, disableAuthorization: false },
  method: Method
) => {
  const { headers, disableAuthorization, ...options } = settings
  const url = `${config.API_URL}/${endpoint}`
  try {
    return axios({
      ...options,
      method,
      url,
      headers: {
        ...getCommonHeaders(settings),
        ...headers,
      },
    })
  } catch (err) {
    if (err.response.status === 401) {
      window.location.replace('/login')
    }
    return Promise.reject(err)
  }
}

export const get = async (endpoint: string, settings?: any) =>
  getRequest(endpoint, settings, 'GET')
export const post = (endpoint: string, settings?: any) =>
  getRequest(endpoint, settings, 'POST')
export const put = (endpoint: string, settings?: any) =>
  getRequest(endpoint, settings, 'PUT')

export default {
  get,
  post,
  put,
}
