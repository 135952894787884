import { createSlice } from '@reduxjs/toolkit'
import {
  handleApiResourceBasePending,
  defaultListedApiResource,
  ListedApiResource,
  handleListedApiResourceFulfilled,
  handleListedApiResourceRejected,
  ApiResource,
  defaultApiResource,
  handleApiResourceFulfilled,
  handleApiResourceRejected,
} from 'store/helpers'
import fetchArticleAction, { ArticleDto } from './actions/fetch-article.action'
import fetchCategoriesAction, {
  CategoryDto,
} from './actions/fetch-categories.action'
import fetchCategoryArticlesAction, {
  CategoryArticleDto,
} from './actions/fetch-category-articles.action'
import { NAMESPACE } from './constants'

interface ArticlesState {
  categories: ListedApiResource<CategoryDto>
  categoryArticles: ListedApiResource<CategoryArticleDto>
  article: ApiResource<ArticleDto>
}

const initialState: ArticlesState = {
  categories: defaultListedApiResource(),
  categoryArticles: defaultListedApiResource(),
  article: defaultApiResource(),
}

const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(fetchCategoriesAction.pending, state =>
        handleApiResourceBasePending(state.categories)
      )
      .addCase(fetchCategoriesAction.fulfilled, (state, action) =>
        handleListedApiResourceFulfilled(state.categories, action.payload)
      )
      .addCase(fetchCategoriesAction.rejected, (state, action) =>
        handleListedApiResourceRejected(state.categories, action.error)
      )
      .addCase(fetchCategoryArticlesAction.pending, state =>
        handleApiResourceBasePending(state.categoryArticles)
      )
      .addCase(fetchCategoryArticlesAction.fulfilled, (state, action) =>
        handleListedApiResourceFulfilled(state.categoryArticles, action.payload)
      )
      .addCase(fetchCategoryArticlesAction.rejected, (state, action) =>
        handleListedApiResourceRejected(state.categoryArticles, action.error)
      )
      .addCase(fetchArticleAction.pending, state =>
        handleApiResourceBasePending(state.article)
      )
      .addCase(fetchArticleAction.fulfilled, (state, action) =>
        handleApiResourceFulfilled(state.article, action.payload)
      )
      .addCase(fetchArticleAction.rejected, (state, action) =>
        handleApiResourceRejected(state.article, action.error)
      ),
})

export const actions = { ...slice.actions, ...slice.caseReducers }

export default slice
