import { createSlice } from '@reduxjs/toolkit'
import { NAMESPACE } from 'store/articles/constants'
import {
  ApiResource,
  defaultApiResource,
  handleApiResourceBasePending,
  handleApiResourceFulfilled,
  handleApiResourceRejected,
} from 'store/helpers'
import fetchLatestWellnessCalendarAction, {
  LatestWellnessCalendarDto,
} from './actions/fetch-latest-wellness-calendar.action'

export interface UploadsState {
  latestWellnessCalendar: ApiResource<LatestWellnessCalendarDto>
}

const initialState: UploadsState = {
  latestWellnessCalendar: defaultApiResource<LatestWellnessCalendarDto>(),
}

const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(fetchLatestWellnessCalendarAction.pending, state =>
        handleApiResourceBasePending(state.latestWellnessCalendar)
      )
      .addCase(fetchLatestWellnessCalendarAction.fulfilled, (state, action) =>
        handleApiResourceFulfilled(state.latestWellnessCalendar, action.payload)
      )
      .addCase(fetchLatestWellnessCalendarAction.rejected, (state, action) =>
        handleApiResourceRejected(state.latestWellnessCalendar, action.error)
      ),
})

export default slice
