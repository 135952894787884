export { default as theme } from './theme'
export { default as colors } from './colors'
export * from './api-endpoints'

export default {
  API_URL: process.env.REACT_APP_API_URL || '',
  API_ASSETS_URL: process.env.REACT_APP_API_ASSETS_URL,
  APP_STATE_KEY: 'appState',
  DEFAULT_DATE_FORMAT: 'YYYY-MM-DD',
  TITLE_DAY_FORMAT: 'dddd, Do MMMM',
  APP_TOKEN_KEY: 'MMN_token',
  REFRESH_TOKEN_KEY: 'MMN_refresh_token',
  TOKEN_EXPIRE_AT: 'MMN_expires_at',
}
